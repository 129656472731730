export default app => {
  const elements = [...document.querySelectorAll('.js-fixed-element')]
  if (!elements.length) return

  elements.forEach(el => {
    const clone = el.cloneNode(true)
    el.parentNode.removeChild(el)
    app.dom.wrap.parentNode.appendChild(clone)
  })
}
