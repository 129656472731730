// import anime from 'animejs/lib/anime.es'
import classes from '../../classNames'
import preventTouchScroll from '../../lib/preventTouchScroll'
import { toggleScroll, allowScroll, isTouch } from '../../helpers'
import { IS_ACTIVE, IS_OPEN, STATE } from '../../constants'

const classNames = classes.menu
let anime

export default class Menu {
  constructor(app, options = {}) {
    this.app = app
    this.options = options
    this.names = []
    this.menus = []
    this.btns = []
  }

  async init() {
    this.btns = [...document.querySelectorAll(`.${classNames.burger}`)]
    this.menus = [...document.querySelectorAll(`.${classNames.menu}`)]

    await this.importLib()

    this.setTouchScrollPreventing('init')
  }

  destroy() {
    this.onClick = null
    this.onKeyUp = null

    this.setTouchScrollPreventing('destroy')
  }

  async importLib() {
    const { default: a } = await import(/* webpackChunkName: "anime" */ 'animejs/lib/anime.es')
    anime = a
  }

  setTouchScrollPreventing(state) {
    if (isTouch && this.menus.length > 0) {
      this.menus.forEach(menu => {
        const pts = preventTouchScroll(menu)
        if (state === 'init') pts.init()
        if (state === 'destroy') pts.destroy()
      })
    }
  }

  onClick = e => {
    this.toggle(e)

    const close = e.target.closest(`.${classNames.close}`)
    if (close) this.close()
  }

  onKeyUp = ({ code }) => {
    if (code === 'Escape') this.close()
  }

  toggle(e) {
    this.btn = e.target.closest(`.${classNames.burger}`)
    if (!this.btn) return

    e.preventDefault()
    e.stopPropagation()

    this.name = this.btn.getAttribute('data-menu-target') || 'default'
    this.menu =
      this.name && this.name !== 'default'
        ? document.querySelector(`.${classNames.menu}[data-menu="${this.name}"]`)
        : document.querySelector(`.${classNames.menu}`)
    if (!this.menu) return

    if (!this.menus.length || !this.btns.length || this.btns.length !== this.menus.length) return

    if (this.btn.getAttribute(STATE) === IS_ACTIVE) {
      this.btn.removeAttribute(STATE)
      this.menu.removeAttribute(STATE)
    } else {
      this.btn.setAttribute(STATE, IS_ACTIVE)
      this.menu.setAttribute(STATE, IS_OPEN)
    }

    if (this.onToggle) this.onToggle()

    if (this.menu.getAttribute(STATE) !== IS_OPEN && this.onClose) {
      this.onClose()
    }
  }

  close() {
    if (!this.btns.length || !this.menus.length) return

    this.btns.forEach(btn => btn.removeAttribute(STATE))
    this.menus.forEach(menu => menu.removeAttribute(STATE))

    if (this.onClose) this.onClose()
  }

  onToggle() {
    const { hasMenuOpen } = this.app.state
    this.app.updateState({ hasMenuOpen: !hasMenuOpen })

    toggleScroll(this.app.state.hasMenuOpen, this.app)
    const img = this.menu.querySelector('.menu__img')
    const nav = this.menu.querySelectorAll('.nav__item')
    const top = this.menu.querySelector('.menu__top')

    if (this.app.state.hasMenuOpen) {
      this.app.dom.root.classList.add('has-menu-open')
      const tl = anime.timeline({
        easing: 'easeInOutSine',
      })

      tl.add({
        targets: img,
        opacity: [0, 0.37],
        duration: 1000,
      })
        .add(
          {
            targets: nav,
            opacity: [0, 1],
            translateX: ['100%', '0%'],
            duration: 750,
            delay: anime.stagger(100),
          },
          0
        )
        .add(
          {
            targets: top,
            opacity: [0, 1],
            duration: 1000,
          },
          0
        )
    } else {
      this.app.dom.root.classList.remove('has-menu-open')
    }
  }

  onClose() {
    this.app.updateState({ hasMenuOpen: false })
    allowScroll(this.app)
    this.app.dom.root.classList.remove('has-menu-open')
  }
}
