import { isTouch } from '../helpers'

export default () => {
  const scene = document.getElementById('pointer-parallax')
  if (!scene) return {}

  if (isTouch) {
    // gyroscope parallax from https://codepen.io/Charlie_Brownie/pen/LjbyNQ?editors=0010
    // due to `parallax-js` plugin adds transform values not enough to see effect.
    const strength = 1

    const parallaxMove = ({ container, x, y, boxWidth, boxHeight }) => {
      const els = [...container.querySelectorAll('[data-depth')]
      if (!els.length) return

      els.forEach(el => {
        const depth = +el.dataset.depth
        const moveX = (boxWidth / 2 - x) * (strength * depth)
        const moveY = (boxHeight / 2 - y) * (strength * depth)

        el.style.transform = `translate3d(${moveX}px, ${moveY}px, 0)`
      })
    }

    const onDeviceOrientation = ({ gamma, beta }) => {
      const rotatedY = Math.min(Math.max(parseInt(Math.floor(gamma), 10), -45), 45)
      const rotatedX = Math.min(Math.max(parseInt(Math.floor(beta), 10), -45), 45)
      const boxWidth = scene.offsetWidth
      const boxHeight = scene.offsetHeight

      const x = ((boxWidth / 2) * rotatedY) / 45
      const y = ((boxWidth / 2) * rotatedX) / 45

      parallaxMove({
        container: scene,
        x,
        y,
        boxWidth,
        boxHeight,
      })
    }

    return {
      onDeviceOrientation,
    }
  }

  let instance = null
  let shouldInit = false
  let Parallax = null

  const getReadyScenesList = () => {
    let result = false

    const elements = [...scene.querySelectorAll('[data-depth')]
    if (
      elements.filter(el => window.getComputedStyle(el).display !== 'none').length ===
      elements.length
    ) {
      result = true
    }

    return result
  }

  const init = async () => {
    shouldInit = getReadyScenesList()

    if (!shouldInit) return

    if (!Parallax) {
      const { default: p } = await import(/* webpackChunkName: "parallax-js" */ 'parallax-js')
      Parallax = p
    }

    instance = new Parallax(scene, {
      hoverOnly: true,
    })
  }

  const update = () => {
    if (instance) instance.destroy()

    init()
  }

  const onResize = () => {
    update()
  }

  return {
    scene,
    instance,
    init,
    onResize,
  }
}
