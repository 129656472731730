export default () => {
  const link = document.querySelector('.js-share-link')
  if (!link) return {}

  const onClick = e => {
    const currentLink = e.target.closest('.js-share-link')
    if (!currentLink) return

    e.preventDefault()

    const params = 'menubar=no,toolbar=no,status=no,width=570,height=570'

    const url = currentLink.href
    window.open(url, 'NewWindow', params)
  }

  return {
    onClick,
  }
}
