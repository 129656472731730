export default app => {
  const BACKGROUND = getComputedStyle(app.dom.root).getPropertyValue('--background') || '#5A5B3C'
  const overlay = document.createElement('div')

  overlay.className = 'layout__overlay overlay'
  overlay.innerHTML = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none">
      <path
        fill="${BACKGROUND}"
        fill-opacity="1"
        d="M0,64L18.5,80C36.9,96,74,128,111,154.7C147.7,181,185,203,222,202.7C258.5,203,295,181,332,197.3C369.2,213,406,267,443,282.7C480,299,517,277,554,245.3C590.8,213,628,171,665,144C701.5,117,738,107,775,133.3C812.3,160,849,224,886,229.3C923.1,235,960,181,997,149.3C1033.8,117,1071,107,1108,122.7C1144.6,139,1182,181,1218,202.7C1255.4,224,1292,224,1329,197.3C1366.2,171,1403,117,1422,90.7L1440,64L1440,320L1421.5,320C1403.1,320,1366,320,1329,320C1292.3,320,1255,320,1218,320C1181.5,320,1145,320,1108,320C1070.8,320,1034,320,997,320C960,320,923,320,886,320C849.2,320,812,320,775,320C738.5,320,702,320,665,320C627.7,320,591,320,554,320C516.9,320,480,320,443,320C406.2,320,369,320,332,320C295.4,320,258,320,222,320C184.6,320,148,320,111,320C73.8,320,37,320,18,320L0,320Z"
      ></path>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none">
      <path
        fill="${BACKGROUND}"
        fill-opacity="1"
        d="M0,64L18.5,80C36.9,96,74,128,111,154.7C147.7,181,185,203,222,202.7C258.5,203,295,181,332,197.3C369.2,213,406,267,443,282.7C480,299,517,277,554,245.3C590.8,213,628,171,665,144C701.5,117,738,107,775,133.3C812.3,160,849,224,886,229.3C923.1,235,960,181,997,149.3C1033.8,117,1071,107,1108,122.7C1144.6,139,1182,181,1218,202.7C1255.4,224,1292,224,1329,197.3C1366.2,171,1403,117,1422,90.7L1440,64L1440,0L1421.5,0C1403.1,0,1366,0,1329,0C1292.3,0,1255,0,1218,0C1181.5,0,1145,0,1108,0C1070.8,0,1034,0,997,0C960,0,923,0,886,0C849.2,0,812,0,775,0C738.5,0,702,0,665,0C627.7,0,591,0,554,0C516.9,0,480,0,443,0C406.2,0,369,0,332,0C295.4,0,258,0,222,0C184.6,0,148,0,111,0C73.8,0,37,0,18,0L0,0Z"
      ></path>
    </svg>`

  app.dom.body.appendChild(overlay)

  app.dom.overlay = overlay
}
