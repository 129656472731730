import classNames from '../../classNames'

export default async app => {
  const section = document.querySelector(`.${classNames.navigation.section}`)

  if (section) {
    const { default: Navigation } = await import(
      /* webpackChunkName: "Navigation" */ './Navigation'
    )
    app.navigation = new Navigation(app)
    app.navigation.init()
  }

  const navigation = document.querySelector('.layout__navigation--static')

  if (navigation) {
    const setBackgroundPercentage = link => {
      const list = link.closest('.page-navigation__list')
      const item = link.closest('.page-navigation__item')
      const progress = item.offsetHeight + item.offsetTop - list.offsetHeight

      list.style.setProperty('--progress', `${progress}px`)
    }

    const onMouseLeave = ({ currentTarget }) => {
      const nav = currentTarget.closest('.page-navigation')
      const list = currentTarget.closest('.page-navigation__list')
      nav.removeAttribute('data-label')
      list.style.removeProperty('--progress')
      currentTarget.removeEventListener('mouseleave', onMouseLeave)
    }

    const onMouseEnter = ({ target }) => {
      const link = target.closest?.('.page-navigation__btn')
      if (!link) return

      const label = link.getAttribute('data-title')
      const nav = link.closest('.page-navigation')

      setBackgroundPercentage(link)

      nav.setAttribute('data-label', label)
      nav.classList.add('fade-in-before')
      setTimeout(() => {
        nav.classList.remove('fade-in-before')
      }, 500)

      link.addEventListener('mouseleave', onMouseLeave)
    }

    app.navigation = {
      onMouseEnter,
    }
  }
}
