import './public-path'
import { debounce, throttle } from 'throttle-debounce'

import { breakpoints, isIOS, isMobile, isModernBrowser, isTouch } from './helpers'
import { DELAYS, DOWN, UP } from './constants'

import loadPolyfills from './polyfills/loadPolyfills'
import setHTMLClassNames from './methods/setHTMLClassNames'
import setAnimations from './methods/animations'
import animateButtons from './methods/animateButtons'
import handlePreloader from './methods/handlePreloader'
import animateTypedText from './methods/animateTypedText'
import animateTitles from './methods/animateTitles'
import appendOverlay from './methods/appendOverlay'
import playVideo from './methods/playVideo'
import setInputMask from './methods/setInputMask'
import handleHeaderOnScroll from './methods/handleHeaderOnScroll'
import setPopups from './methods/setPopups'
import replaceFixedElements from './methods/replaceFixedElements'
// import renderInstagramPhotos from './methods/renderInstagramPhotos'

import setLazy from './components/LazyLoader/setLazy'
import Menu from './components/Menu/Menu'
import setSlider from './components/Slider/setSlider'
import paddings from './components/paddings'
import setCurve from './components/Curve/setCurve'
import setNavigation from './components/Navigation/setNavigation'
import setParallax from './components/Parallax/setParallax'
import pointerParallax from './components/pointerParallax'
import heroButton from './components/heroButton'
import setNoise from './components/Noise/setNoise'
import Offline from './components/Offline/Offline'
import setPointer from './components/Pointer/setPointer'
import setScroll from './components/Scroll/setScroll'
import handleShareLinks from './methods/handleShareLinks'
import animateMenuImages from './methods/animateMenuImages'
import setSectionScrollAnimator from './components/SectionScrollAnimator/setSectionScrollAnimator'
import handleForms from './methods/handleForms'
import handleIOSDeviceOrientationListener from './methods/handleIOSDeviceOrientationListener'

handlePreloader()
class App {
  constructor() {
    this.dom = {
      root: document.documentElement,
      body: document.body,
      wrap: document.querySelector('.layout__wrap'),
      header: document.querySelector('.layout__header'),
    }
    this.LANGUAGE = this.dom.root.getAttribute('lang')
      ? this.dom.root.getAttribute('lang').split('-')[0].toLowerCase()
      : 'en'
    this.state = {
      hasMenuOpen: false,
      hasPopupOpen: false,
      scrollDirection: null,
      lastScrollTop: 0,
      pointerCoordinates: { x: window.innerWidth / 2, y: window.innerHeight / 2 },
    }

    this.menu = new Menu(this)
    this.offline = new Offline(this)

    this.paddings = paddings(this)
    this.pointerParallax = pointerParallax(this)
    this.videoPlayer = playVideo(this)
    this.headerOnScroll = handleHeaderOnScroll(this)
    this.articleSharer = handleShareLinks()
    this.menuImages = animateMenuImages(this)

    this.masks = []
    this.maps = []

    this.lazyLoader = null
    this.heroButton = null
    this.scroll = null
    this.curve = null
    this.slider = null
    this.navigation = null
    this.parallax = null
    this.noise = null
    this.pointer = null
    this.popup = null
    this.sectionScrollAnimator = null
  }

  updateState(state) {
    this.state = {
      ...this.state,
      ...state,
    }
  }

  getScrollDirection() {
    const yOffset = this.scroll?.scrollbar.scrollTop ?? window.pageYOffset

    if (yOffset > this.state.lastScrollTop) {
      this.updateState({ scrollDirection: DOWN })
    } else {
      this.updateState({ scrollDirection: UP })
    }
    this.updateState({
      lastScrollTop: yOffset <= 0 ? 0 : yOffset,
    })
  }

  getPointerCoordinates({ clientX, clientY }) {
    this.updateState({
      pointerCoordinates: { x: clientX, y: clientY },
    })
  }

  async initComponents() {
    this.heroButton = await heroButton(this)
    setNoise(this) // Remove canvas noise for performance reasons. Now it just adds html root element class name.
    await setLazy(this)
    await setPointer(this)
    await setScroll(this)
    await setCurve(this)
    await setSlider(this)
    await setNavigation(this)
    await setParallax(this)
    await setAnimations(this)
    await setPopups(this)
    await setSectionScrollAnimator(this)
  }

  initMethods() {
    setHTMLClassNames(this)
    animateButtons()
    animateTypedText()
    animateTitles()
    setInputMask(this)
    replaceFixedElements(this)
    handleForms(this)
    // renderInstagramPhotos(this)

    if (this.navigation) appendOverlay(this)
  }

  onClickHandler = e => {
    this.getPointerCoordinates(e)
    this.menu.onClick?.(e)
    this.heroButton.onClick?.(e)
    this.videoPlayer.onClick?.(e)
    this.articleSharer.onClick?.(e)
  }

  onKeyupHandler = e => {
    this.menu.onKeyUp?.(e)
  }

  onKeyDownHandler = e => {
    this.scroll?.onKeyDown(e)
  }

  onResizeHandler = () => {
    if (this.curve) debounce(DELAYS.long, this.curve.onResize())
    if (this.paddings) debounce(DELAYS.long, this.paddings.onResize())
    if (this.noise) debounce(DELAYS.long, this.noise.onResize())
    if (this.pointerParallax.onResize) debounce(DELAYS.long, this.pointerParallax.onResize())
  }

  onScrollHandler = () => {
    this.getScrollDirection()

    this.headerOnScroll.onScroll()
    this.curve?.onScroll()
    if (this.navigation?.onScroll) throttle(DELAYS.min, this.navigation.onScroll())
    this.parallax?.onScroll?.()
    if (this.sectionScrollAnimator?.onScroll) {
      this.sectionScrollAnimator.onScroll()
    }
  }

  onMouseEnterHandler = e => {
    this.menuImages.onMouseEnter?.(e)
    this.navigation?.onMouseEnter?.(e)
  }

  onMouseMoveHandler = e => {
    this.pointer?.onMouseMove(e)
  }

  onOfflineHandler = () => {
    this.offline.onOffline()
  }

  onOnlineHandler = () => {
    this.offline.onOnline()
  }

  onDeviceOrientationHandler = e => {
    this.pointerParallax.onDeviceOrientation(e)
  }

  onXSBreakpointChange = () => {
    this.parallax?.onBreakpointChange()
  }

  onXLBreakpointChange = () => {
    this.navigation?.onBreakpointChange()
  }

  onXXXLBreakpointChange = () => {
    this.scroll?.onBreakpointChange()
  }

  async addListeners() {
    document.addEventListener('click', this.onClickHandler)
    document.addEventListener('keyup', this.onKeyupHandler)
    document.addEventListener('keydown', this.onKeyDownHandler)
    window.addEventListener('resize', this.onResizeHandler)
    window.addEventListener('offline', this.onOfflineHandler)
    window.addEventListener('online', this.onOnlineHandler)
    if (!this.scroll) window.addEventListener('scroll', this.onScrollHandler)
    if (this.pointer) document.addEventListener('mousemove', this.onMouseMoveHandler)
    if (!isTouch) document.addEventListener('mouseenter', this.onMouseEnterHandler, true)

    breakpoints.xs.addEventListener('change', this.onXSBreakpointChange)
    breakpoints.xl.addEventListener('change', this.onXLBreakpointChange)
    breakpoints.xxxl.addEventListener('change', this.onXXXLBreakpointChange)

    if (isMobile && !isIOS && this.pointerParallax.onDeviceOrientation) {
      window.addEventListener('deviceorientation', this.onDeviceOrientationHandler)
    }

    if (isTouch && isIOS && this.pointerParallax.onDeviceOrientation) {
      handleIOSDeviceOrientationListener(this)
    }
  }

  async init() {
    await this.initComponents()
    this.initMethods()
    this.addListeners()

    this.lazyLoader?.init()
    this.menu.init()
    this.paddings?.init()
    this.pointerParallax.init?.()
    this.slider?.init()
    this.popup?.init()
    this.offline.init()
  }
}

const init = async () => {
  const app = new App()
  await app.init()
}

if (isModernBrowser) {
  document.addEventListener('DOMContentLoaded', init)
} else {
  document.addEventListener('DOMContentLoaded', loadPolyfills(init))
}
