import animateInView from './animateInView'
import classNames from '../../classNames'
import { _UID } from '../../helpers'
import { IS_LOADED } from '../../constants'

export default async app => {
  const allAnimations = []

  const importAnimation = async (section, path = '') => {
    try {
      const { default: animation } = await import(`${path}`)
      const { enter, leave } = animation(section, app)

      return {
        enter,
        leave,
      }
    } catch (error) {
      console.error('Animation script import error', error)
    }

    return {}
  }

  const getElements = (name = '') =>
    name === 'section'
      ? [...document.querySelectorAll(`.${classNames.animatedSection}:not([data-section])`)]
      : [...document.querySelectorAll(`.${classNames.animatedSection}[data-section="${name}"]`)]

  const getSectionData = async (name = '', pathToScript = '') => {
    const _uid = _UID()

    return {
      els: getElements(name),
      async onEnter(section) {
        const anim = allAnimations.find(({ id }) => id === _uid)
        const { enter, leave } = anim?.callbacks || (await importAnimation(section, pathToScript))
        enter?.()

        if (!anim) {
          allAnimations.push({
            id: _uid,
            section,
            callbacks: { enter, leave },
          })
        }
      },
      onLeave() {
        const { leave } = allAnimations.find(({ id }) => id === _uid)?.callbacks || {}
        leave?.()
      },
    }
  }

  const sections = {
    hero: await getSectionData('hero', './heroAnimation'),
    about: await getSectionData('about', './aboutAnimation'),
  }

  const line = app.curve?.wrap
  const lineWrap = line?.parentNode
  // const header = document.querySelector('.layout__header')

  const { default: anime } = await import(/* webpackChunkName: "anime" */ 'animejs/lib/anime.es')

  anime({
    targets: app.dom.header,
    translateY: ['-100%', '0%'],
    opacity: 1,
    duration: 750,
    easing: 'easeOutSine',
    complete() {
      app.dom.header.style.transform = ''
      app.dom.header.classList.add(`layout__header--${IS_LOADED}`)
    },
  })

  if (lineWrap) {
    anime({
      targets: lineWrap,
      opacity: 1,
      duration: 1000,
      delay: 1000,
      easing: 'linear',
    })
  }

  Object.values(sections).forEach(async ({ els = [], onEnter, onLeave } = {}) => {
    if (!els.length) return

    els.forEach(section => {
      animateInView({
        // once: false, // TODO: Uncomment, if need to add infinity animations triggering.
        section,
        app,
        onEnter,
        onLeave,
      })
    })
  })
}
