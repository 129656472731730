import classNames from '../classNames'
import { IS_ACTIVE, STATE } from '../constants'
import scrollTo from '../methods/scrollTo'

export default async app => {
  const heroBtn = document.querySelector(`.${classNames.heroBtn}`)

  if (!heroBtn) return {}

  const { default: anime } = await import(/* webpackChunkName: "anime" */ 'animejs/lib/anime.es')

  const onClick = ({ target }) => {
    const btn = target.closest(`.${classNames.heroBtn}`)
    if (!btn) return

    btn.setAttribute(STATE, IS_ACTIVE)
    const btnCircleAnimationDuration = 1000
    setTimeout(() => btn.removeAttribute(STATE), btnCircleAnimationDuration)

    const section = document.querySelector('.js-navigation-target')

    scrollTo({
      app,
      anime,
      index: 0,
      target: section,
    })
  }

  return {
    btn: heroBtn,
    onClick,
  }
}
