import { isTouch } from '../../helpers'

export default async app => {
  if (isTouch) return

  const { dom, onScrollHandler } = app

  const { default: Scroll } = await import(/* webpackChunkName: "Scroll" */ './Scroll')
  app.scroll = new Scroll(dom.wrap, {
    callback: onScrollHandler,
  })
  app.scroll.init()
}
