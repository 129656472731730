import classNames from '../classNames'

export default async () => {
  const slogs = [...document.querySelectorAll(`.${classNames.slog}`)]
  if (!slogs.length) return

  const defaultOptions = {
    loop: false,
    typeSpeed: 40,
    showCursor: false,
    startDelay: 0,
  }

  const { init } = await import(/* webpackChunkName: "ityped" */ 'ityped')

  slogs.forEach(slog => {
    const quote = slog.querySelector('.s-banner__quote')
    const caption = slog.querySelector('.s-banner__caption')

    const quoteText = quote.innerHTML
    const captionText = caption?.innerHTML

    quote.style.height = `${quote.getBoundingClientRect().height}px`
    if (caption) caption.style.height = `${caption.getBoundingClientRect().height}px`

    quote.innerHTML = ''
    if (caption) caption.innerHTML = ''

    const typeCaption = () => {
      if (!caption) return

      init(caption, {
        ...defaultOptions,
        strings: [captionText],
        onFinished() {
          caption.style.height = ''
        },
      })
    }

    const typeQuote = () => {
      init(quote, {
        ...defaultOptions,
        strings: [quoteText],
        onFinished() {
          quote.style.height = ''
          typeCaption()
        },
      })
    }

    const onIntersecting = (entries, observer) => {
      entries.forEach(({ isIntersecting, target }) => {
        if (isIntersecting) {
          typeQuote()
          observer.unobserve(target)
        }
      })
    }

    const observer = new IntersectionObserver(onIntersecting, {
      threshold: 0.9,
    })
    observer.observe(quote)
  })
}
