export const {
  STATE,
  IS_ACTIVE,
  IS_OPEN,
  IS_VISIBLE,
  IS_READY,
  IS_LOADED,
  IS_ANIMATING,
  IS_PLAYING,
  IS_SHRINKED,
  IS_FIXED,
  IS_DISABLED,
  NO_TOUCH,
  HAS_ERROR,
  DELAYS,
  UP,
  DOWN,
  ONLINE,
  OFFLINE,
} = {
  STATE: 'data-state',
  IS_ACTIVE: 'active',
  IS_OPEN: 'open',
  IS_VISIBLE: 'visible',
  IS_READY: 'is-ready',
  IS_LOADED: 'loaded',
  IS_ANIMATING: 'animating',
  IS_PLAYING: 'playing',
  IS_SHRINKED: 'shrinked',
  IS_FIXED: 'fixed',
  IS_DISABLED: 'disabled',
  NO_TOUCH: 'no-touch',
  HAS_ERROR: 'error',
  UP: -1,
  DOWN: 1,
  ONLINE: 'online',
  OFFLINE: 'offline',
  DELAYS: {
    min: 66,
    short: 100,
    medium: 200,
    long: 300,
  },
}
