import { allowScroll, preventScroll, setPointerCoordinatesVariables } from '../helpers'

export default async app => {
  const el = document.querySelector('.js-popup')
  if (!el) return

  const BLUR_TRANSITION_DURATION = 400
  let shouldAddBlur = false

  const addBlur = () => {
    const { wrap: layoutWrap } = app.dom

    shouldAddBlur = true
    layoutWrap.style.transition = `filter ${BLUR_TRANSITION_DURATION}ms`
    layoutWrap.style.filter = 'blur(10px)'
  }

  const removeBlur = () => {
    const { wrap: layoutWrap } = app.dom
    layoutWrap.style.filter = ''

    setTimeout(() => {
      layoutWrap.style.transition = ''
      shouldAddBlur = false
    }, BLUR_TRANSITION_DURATION)
  }

  const { default: Popup } = await import(/* webpackChunkName: "Popup" */ 'popup-simple/index.es')
  const { default: anime } = await import(/* webpackChunkName: "anime" */ 'animejs')

  app.popup = new Popup({
    preventScroll: false,
  })

  app.popup.onOpen = () => {
    setPointerCoordinatesVariables(app)

    app.updateState({
      hasPopupOpen: true,
    })
    app.dom.root.classList.add('has-popup-open')

    if (!app.state.hasMenuOpen) preventScroll()

    if (app.popup.popup?.classList.contains('popup--blur')) addBlur()

    const inner = app.popup.popup?.querySelector('.popup__inner')
    const video = app.popup.popup?.querySelector('video')

    if (inner) {
      const tl = anime.timeline({
        easing: 'easeInOutSine',
      })

      tl.add({
        targets: inner,
        duration: 750,
        opacity: [0, 1],
      })
    }

    if (video) video.play()
  }

  app.popup.onClose = () => {
    app.updateState({
      hasPopupOpen: app.popup.openPopups.length > 0,
    })

    if (!app.state.hasPopupOpen && !app.state.hasMenuOpen) allowScroll()
    app.dom.root.classList.remove('has-popup-open')

    const video = app.popup.popup?.querySelector('video')
    if (video) video.pause()

    if (shouldAddBlur) removeBlur()
  }
}
