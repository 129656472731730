import classNames from '../../classNames'

export default async app => {
  const el = document.querySelector(`.${classNames.parallax}`)
  if (!el) return

  const { default: Parallax } = await import(/* webpackChunkName: "Parallax" */ './Parallax')
  app.parallax = new Parallax()
  app.parallax.init()
}
