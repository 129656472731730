import { IS_DISABLED } from '../constants'

export default app => {
  const forms = [...document.querySelectorAll('.js-form')]
  if (!forms.length) return

  const submitStatuses = {
    OK: 'OK',
    PENDING: 'PENDING',
    ERROR: 'ERROR',
  }

  const fieldsNames = {
    feedback: ['name', 'email', 'phone', 'message'],
  }

  let formDataNaitve
  let submitStatus = null

  const getFormData = type => {
    const data = {}

    try {
      fieldsNames[type].forEach(name => {
        data[name] = formDataNaitve.get(name)
      })

      return data
    } catch (error) {
      throw new Error(
        'Error in `getFormData()` method is occured. No type or no array of fields names were provided.',
        error
      )
    }
  }

  const handleFeedback = async ({ currentTarget }) => {
    const submit = currentTarget.querySelector('button')
    const telInput = currentTarget.querySelector('[type="tel"]')
    const maskInput = app.masks.find(({ input } = {}) => input === telInput)

    submit.setAttribute(IS_DISABLED, '')

    const date = new Date().toLocaleString('uk-UA')
    let message = `Дата: ${date}`

    const { name, email, phone, message: mess } = getFormData('feedback')

    if (name) message += `\nИмя: ${name}`
    if (email) message += `\nEmail: ${email}`
    if (phone) message += `\nТелефон: ${phone}`
    if (mess) message += `\nСообщение: ${mess}`

    const handleSuccess = () => {
      submitStatus = submitStatuses.OK

      submit.removeAttribute(IS_DISABLED)

      if (maskInput) {
        maskInput.mask.value = ''
        maskInput.inputValue = ''
      }
      currentTarget.reset()

      const thanksPopupTarget = document.getElementById('popup-thanks')
      app.popup?.openTarget(thanksPopupTarget)
    }

    const handleError = res => {
      submitStatus = submitStatuses.ERROR
      console.error({
        telegramResponse: res.statusText,
      })
    }

    try {
      submitStatus = submitStatuses.PENDING

      // Send data to telegram chat.
      const telegramResponse = await fetch(
        `${process.env.TELEGRAM_API_URL}/bot${process.env.TELEGRAM_BOT_TOKEN}/sendMessage`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            chat_id: process.env.TELEGRAM_CHAT_ID,
            text: message,
          }),
        }
      )

      if (telegramResponse.ok) {
        handleSuccess()
      } else {
        handleError(telegramResponse)
      }
    } catch (error) {
      submitStatus = submitStatuses.ERROR
      throw new Error('Form submit error', error)
    }
  }

  const onSubmit = e => {
    e.preventDefault()

    formDataNaitve = new FormData(e.currentTarget)

    const type = e.currentTarget.dataset.type || 'feedback'
    if (type === 'feedback') handleFeedback(e)
  }

  forms.forEach(form => form.addEventListener('submit', onSubmit))
}
