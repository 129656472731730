import { IS_VISIBLE, STATE } from '../constants'

export default () => {
  const titles = [...document.querySelectorAll('.section__title')]
  const textBlocks = [...document.querySelectorAll('.section-block--line')]

  const handleAnimation = els => {
    const targets = els.filter(el => +window.getComputedStyle(el).opacity !== 0)

    const onIntersecting = (entries, observer) => {
      entries.forEach(({ isIntersecting, target }) => {
        if (isIntersecting) {
          target.setAttribute(STATE, IS_VISIBLE)
          observer.unobserve(target)
        }
      })
    }

    const observer = new IntersectionObserver(onIntersecting, {
      threshold: 1,
    })

    targets.forEach(target => observer.observe(target))
  }

  if (titles.length > 0) handleAnimation(titles)
  if (textBlocks.length > 0) handleAnimation(textBlocks)
}
