import classNames from '../../classNames'

export default async app => {
  const wrap = document.querySelector(`.${classNames.line.wrap}`)
  if (!wrap) return

  const { default: Curve } = await import(/* webpackChunkName: "Curve" */ './Curve')
  app.curve = new Curve(wrap, app)
  app.curve.init()
}
