import { breakpoints } from '../helpers'

export default () => {
  const sideSliders = [...document.querySelectorAll('.slider--items-side__wrap')]
  if (!sideSliders.length) return null

  const setSideSliderPadding = nmb => {
    sideSliders.forEach(slider => {
      const container = slider.querySelector('.slider__swiper-container-wrap')

      if (!container) return

      if (nmb === 0) {
        container.style.paddingLeft = ''
        return
      }

      const elements = slider.querySelector('.slider__elements')
      if (!elements) return

      const { left } = elements.getBoundingClientRect()
      const GAP = 82

      container.style.paddingLeft = `${left + GAP}px`
    })
  }

  const init = () => {
    if (!breakpoints.sm.matches) return
    setSideSliderPadding()
  }

  const onResize = () => {
    if (!breakpoints.sm.matches) {
      setSideSliderPadding(0)
      return
    }
    setSideSliderPadding()
  }

  return {
    init,
    onResize,
  }
}
