import { breakpoints } from '../../helpers'

export default ({ section, app, onEnter, onLeave, once = true, options = {} }) => {
  const rootMargin = breakpoints.md.matches ? '-200px 0px -200px 0px' : '-150px 0px -150px 0px'

  let isInited = false

  if (!{}.hasOwnProperty.call(options, 'rootMargin')) {
    options.rootMargin = rootMargin
  }

  const onIntersection = (entries, observer) => {
    entries.forEach(({ isIntersecting, target }) => {
      if (isIntersecting) {
        if (!isInited) isInited = true
        onEnter(target, app)
      } else if (isInited) {
        onLeave(target, app)
        if (once) observer.unobserve(target)
      }
    })
  }
  const observer = new IntersectionObserver(onIntersection, options)
  observer.observe(section)
}
