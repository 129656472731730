import { DOWN, IS_FIXED, IS_SHRINKED, STATE } from '../constants'
import { breakpoints } from '../helpers'

export default app => {
  const {
    dom: { header, wrap },
  } = app
  const headerHeight = header.offsetHeight
  let pageYOffset

  const fixElementsToTop = () => {
    if (!app.scroll?.scrollbar) return

    const OFFLINE_PANEL_HEIGHT = app.offline.panelHeight || 0
    const top = !navigator.onLine ? pageYOffset + OFFLINE_PANEL_HEIGHT : pageYOffset

    header.style.top = `${top}px`
  }

  const showHeader = () => {
    fixElementsToTop()

    if (pageYOffset > headerHeight) {
      header.classList.remove(`layout__header--${IS_SHRINKED}`)
      header.setAttribute(STATE, IS_FIXED)
      wrap.classList.add('layout__wrap--has-fixed-header')
    }

    if (pageYOffset <= 0) {
      header.removeAttribute(STATE)
      wrap.classList.remove('layout__wrap--has-fixed-header')

      if (app.scroll?.scrollbar) header.style.top = ''
    }
  }

  const hideHeader = () => {
    if (!breakpoints.md.matches) return

    if (pageYOffset > headerHeight) {
      header.classList.add(`layout__header--${IS_SHRINKED}`)
    }
  }

  const toggleHeader = () => {
    pageYOffset = app.scroll?.scrollbar.scrollTop ?? window.scrollY

    const handleDesktop = () => {
      if (app.state.scrollDirection === DOWN) {
        hideHeader()
      } else {
        showHeader()
      }

      if (pageYOffset <= 0) {
        header.removeAttribute(STATE)
        if (app.scroll?.scrollbar) header.style.top = ''
      }
    }

    const handleMobile = () => {
      fixElementsToTop()

      if (pageYOffset >= headerHeight) {
        header.setAttribute(STATE, IS_FIXED)
      } else {
        header.removeAttribute(STATE)
      }
    }

    if (breakpoints.md.matches) handleDesktop()
    else handleMobile()
  }

  const onScroll = () => {
    toggleHeader()
  }

  return {
    onScroll,
  }
}
