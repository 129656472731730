export default app => {
  const shouldAddNoise = document.querySelector('.is-noise-bg')
  if (!shouldAddNoise) return

  // const { default: Noise } = await import(/* webpackChunkName: "Noise" */ './Noise')
  // app.noise = new Noise(app)
  // app.noise.init()

  // const wrap = document.createElement('div')
  // wrap.className = 'layout__bg noise'

  // app.dom.body.appendChild(wrap)

  app.dom.root.classList.add('has-noise-bg')
}
