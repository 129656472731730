import { IS_PLAYING, STATE } from '../constants'

export default () => {
  const player = document.querySelector('.js-youtube-player')
  if (!player) return {}

  const onClick = ({ target }) => {
    const figure = target.closest('.js-youtube-player')
    if (!figure) return

    const Iframe = ({ src, height }) => `
      <iframe
        class="youtube-player__img"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen=""
        src="${src}"
        height="${height}"
      ></iframe>
    `

    const caption = figure.querySelector('.youtube-player__caption')
    const img = figure.querySelector('.youtube-player__img')
    if (!caption || !img) return

    let { src } = figure.dataset
    src += '?autoplay=1&rel=0&showinfo=0'
    const height = figure.offsetHeight

    const template = document.createElement('template')
    template.innerHTML = Iframe({ src, height })
    const iframe = template.content.cloneNode(true)

    figure.insertBefore(iframe, img)
    figure.removeChild(img)

    figure.setAttribute(STATE, IS_PLAYING)
    figure.removeChild(caption)
  }

  return {
    onClick,
  }
}
