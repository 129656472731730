import { isTouch } from '../helpers'

export default () => {
  if (isTouch) return {}

  let startX = 0
  let startY = 0

  let yTranslation = 0
  let xTranslation = 0

  let image = null
  let link = null
  let shouldAnimate = false

  const TRANSITION_DURATION = 750

  const onMouseMove = ({ clientX, clientY }) => {
    if (!shouldAnimate || !image) return

    xTranslation = (startX - clientX) / 10
    yTranslation = (startY - clientY) / 10

    image.style.transform = `skew(0) translate3d(${xTranslation}px, ${yTranslation}px, 0) rotate(-11deg)`
  }

  const onMouseLeave = ({ currentTarget }) => {
    if (!image) return

    image.style.transition = ''
    image.style.transform = ''
    shouldAnimate = false

    currentTarget.removeEventListener('mouseleave', onMouseLeave)
    currentTarget.removeEventListener('mousemove', onMouseMove)
  }

  const onMouseEnter = ({ target, clientX, clientY }) => {
    link = target.classList?.contains('js-menu-link') ? target : null
    if (!link) return

    image = link.querySelector('.nav__img')

    link.addEventListener('mousemove', onMouseMove)
    link.addEventListener('mouseleave', onMouseLeave)

    setTimeout(() => {
      startX = clientX
      startY = clientY

      shouldAnimate = true

      image.style.transition = 'opacity .75s, visibility .75s, transform .5s linear'
    }, TRANSITION_DURATION)
  }

  return {
    onMouseEnter,
  }
}
