import { IS_VISIBLE } from '../constants'

export default () => {
  const btns = [...document.querySelectorAll('.btn:not(.btn--filled)')]
  if (!btns.length) return

  const onIntersecting = (entries, observer) => {
    entries.forEach(({ isIntersecting, target }) => {
      if (isIntersecting) {
        target.classList.add(`btn--${IS_VISIBLE}`)
        observer.unobserve(target)
      }
    })
  }

  const observer = new IntersectionObserver(onIntersecting, {
    threshold: 1,
  })

  btns.forEach(btn => observer.observe(btn))
}
