const messages = {
  message: {
    uk: 'Дозволити доступ до даних руху і орієнтації?',
  },
  button: {
    uk: {
      allow: 'так',
      decline: 'ні',
    },
  },
}

const Panel = ({ message, button } = {}) => `
<div class="layout__request-panel request-panel">
  <div class="container">
    <div class="request-panel__inner">
      <p class="request-panel__text">${message}</p>
      <div class="request-panel__buttons">
        <button type="button" class="request-panel__button btn btn--sm btn--filled" data-action="allow">
          <span class="btn__title">${button.allow}</span>
        </button>
        <button ype="button" class="request-panel__button btn btn--sm" data-action="decline">
          <span class="btn__title">${button.decline}</span>
        </button>
      </div>
    </div>
  </div>
</div>
`

export default app => {
  let panel
  let buttons = []

  const appendPanel = () => {
    const tpl = document.createElement('template')
    tpl.innerHTML = Panel({
      message: messages.message[app.LANGUAGE],
      button: messages.button[app.LANGUAGE],
    })

    panel = tpl.content.cloneNode(true).querySelector('.request-panel')
    buttons = [...panel.querySelectorAll('.request-panel__button')]

    app.dom.body.appendChild(panel)
  }

  const addDeviceOrientationEvent = async () => {
    if (typeof DeviceOrientationEvent.requestPermission === 'function') {
      // iOS 13+
      try {
        const res = await DeviceOrientationEvent.requestPermission()
        if (res === 'granted') {
          window.addEventListener('deviceorientation', app.onDeviceOrientationHandler)
        }
      } catch (error) {
        throw new Error(error)
      }
    }
  }

  const onButtonClick = async ({ currentTarget }) => {
    const { action } = currentTarget.dataset

    if (action === 'allow') await addDeviceOrientationEvent()
    buttons.forEach(button => {
      button.removeEventListener('click', onButtonClick)
    })
    app.dom.body.removeChild(panel)
  }

  appendPanel()

  buttons.forEach(button => {
    button.addEventListener('click', onButtonClick)
  })
}
