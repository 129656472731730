export default async app => {
  const section = document.querySelector('.js-banner-scroll-animated')
  if (!section) return

  const { default: SectionScrollAnimator } = await import(
    /* webpackChunkName: "SectionScrollAnimator" */ './SectionScrollAnimator'
  )
  app.sectionScrollAnimator = new SectionScrollAnimator(app)
  app.sectionScrollAnimator.observe()
}
