import { DELAYS, DOWN, UP } from '../constants'
import { breakpoints } from '../helpers'

export default ({
  app = {},
  anime,
  index,
  scrollDuration = DELAYS.min,
  behavior = breakpoints.xl.matches ? 'smooth' : 'auto',
  target,
} = {}) => {
  const scrollbar = app.scroll?.scrollbar
  const rootOffset = scrollbar?.scrollTop ?? app.dom.root.scrollTop
  const sectionTop = target?.getBoundingClientRect().top + rootOffset ?? 0
  const top = breakpoints.xs.matches
    ? app.curve?.dot.constants.STOPS[index]?.scrollTop || sectionTop
    : sectionTop
  // const top = sectionTop

  const pageYOffset = scrollbar?.scrollTop ?? window.scrollY
  const headerHeight = app.dom?.header.offsetHeight ?? 0

  const direction = top > pageYOffset ? DOWN : UP
  const offset = !breakpoints.xs.matches || direction === UP ? headerHeight : 0

  const scroll = () => {
    scrollbar
      ? scrollbar.scrollTo(0, top - offset, scrollDuration)
      : window.scrollTo({ top: top - offset, behavior }) // `scrollDuration` - delay to trigger navigation setProgress.
  }

  const { overlay } = app.dom

  anime({
    targets: overlay,
    translateY: ['150%', '-150%'],
    duration: 2000,
    easing: 'easeOutExpo',
  })

  setTimeout(scroll, 300) // Wait for the overlay to cover page.
}
