export default {
  pointerParallax: 'js-pointer-parallax',
  parallax: 'js-parallax',
  animatedSection: 'js-animated-section',
  slog: 'js-typed-slog',
  noise: 'js-noise',
  heroBtn: 'js-hero-button',
  line: {
    wrap: 'js-line',
    dot: 'js-line-dot',
    path: 'js-line-path',
  },
  menu: {
    burger: 'js-burger',
    menu: 'js-menu',
    close: 'js-menu-close',
  },
  slider: {
    container: 'js-slider',
    slider: {
      wrap: 'slider__wrap',
      prev: 'js-slider-prev',
      next: 'js-slider-next',
      pagination: 'js-slider-pagination',
      slide: 'swiper-slide',
      slideThumb: 'js-slider-thumb',
    },
    plugin: {
      container: 'swiper-container',
      initialized: 'swiper-container-initialized',
      activeSlide: 'swiper-slide-active',
    },
  },
  navigation: {
    wrap: 'js-navigation',
    section: 'js-navigation-target',
  },
}
